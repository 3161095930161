<template>
  <div class="m-varuscafe-mobile-filter">
    <template v-for="(filters, filterType) of getCorrectFilters">
      <div
        :key="filterType"
        class="m-sidebar-mobile-filters__item"
      >
        <SfButton
          class="sf-button--text m-sidebar-mobile-filters__button"
          @click="openSidebar(filterType)"
        >
          {{ filters.nameLabel }}
          <div
            class="sf-button--subtitle"
            v-if="filterType !== varusCafeAttrCode"
          >
            <span
              v-for="(filter, index) of currentFilter(filters.nameFilter, filterType)"
              :key="index"
            >
              {{ filter.label }}<span class="coma">,</span>
            </span>
          </div>
          <div class="sf-button--subtitle" v-else>
            <span v-show="getSubtitlesForCategory !== ''">
              {{ getSubtitlesForCategory }}<span class="coma">,</span>
            </span>
          </div>
        </SfButton>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from 'config'
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'MVaruscafeMobileFilter',
  components: {
    SfButton
  },
  props: {
    availableFilters: {
      type: Object,
      default: () => ({})
    },
    currentFilter: {
      type: Function
    },
    openSidebar: {
      type: Function
    }
  },
  data () {
    return {
      varusCafeAttrCode: config.attributesCodes.varusCafe
    }
  },
  computed: {
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory'
    }),
    getCorrectFilters () {
      const { varuscafeCategory } = this.availableFilters

      const filters = {
        [this.varusCafeAttrCode]: {
          nameFilter: varuscafeCategory,
          nameLabel: this.$t('Category')
        }
      }

      return filters
    },
    getSubtitlesForCategory () {
      return this.getCurrentCategory.children_count ? '' : this.getCurrentCategory.name
    }
  }
}
</script>
